// @flow
import includes from 'lodash/includes';

export default function getImageUrl(urlObject: any): string {
  if (Array.isArray(urlObject)) {
    if (urlObject.length === 0) return '';
    else urlObject = urlObject[0];
  }
  if (!urlObject || !urlObject.file) return '';
  else {
    const url: string = urlObject.file.url;
    if (!includes(url, 'http')) {
      return url.replace('//', 'https://');
    }
    return url;
  }
}