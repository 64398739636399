// @flow

import React from "react";
import { observer } from "mobx-react";
import Helmet from "react-helmet";
import getImageUrl from "../../utils/get-image-url";
import { graphql, useStaticQuery } from "gatsby";

type MetadataProps = {
  data: {
    title?: string,
    description?: {
      text: string,
    },
    shareImage?: any,
  },
  location: {
    origin: string,
    pathname: string,
  },
};

function DataWrapper(props: MetadataProps) {
  const defaultData = useStaticQuery(graphql`
    query {
      metadata: contentfulMetadata(
        contentful_id: { eq: "5BymrGGsLao5gt7VK6U9Vp" }
      ) {
        title
        description {
          text: description
        }
        shareImage {
          file {
            url
          }
        }
      }
    }
  `);
  const jsonData = props.data;
  const location = props.location;
  return (
    <Helmet
      title={jsonData.title ? jsonData.title : defaultData.metadata.title}
      meta={[
        {
          name: "ahrefs-site-verification",
          content:
            "48a7cc2d0aaf88c335b549d702cf32d796bec15633bca18f2fd7be6b3079247d",
        },
        {
          name: "description",
          content:
            jsonData.description && jsonData.description.text
              ? jsonData.description.text
              : defaultData.metadata.description.text,
        },
        { name: "og:type", content: "website" },
        { name: "og:url", content: `${location.origin}${location.pathname}` },
        {
          name: "og:title",
          content: jsonData.title ? jsonData.title : defaultData.metadata.title,
        },
        {
          name: "og:description",
          content: jsonData.description
            ? jsonData.description.text
            : defaultData.metadata.description.text,
        },
        {
          name: "og:image",
          content: jsonData.shareImage
            ? getImageUrl(jsonData.shareImage)
            : getImageUrl(defaultData.metadata.shareImage),
        },
        { name: "twitter:card", content: "summary" },
      ]}
    >
      <html lang="en" />
    </Helmet>
  );
}

@observer
class Metadata extends React.Component<MetadataProps, any> {
  render() {
    return (
      <DataWrapper data={this.props.data} location={this.props.location} />
    );
  }
}

export default Metadata;
